import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import DataTable from 'react-data-table-component';
import axios from "axios";
import {
    SENT_FRIEND_REQUEST,
    SENT_FRIEND_REQUEST_DELETE,

} from "_helpers/api";
import Loader from "_components/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Select from "react-dropdown-select";

export default function FriendRequest() {
    const { user: authUser } = useSelector((x) => x.auth);
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    let [requestdata, updateRequestData] = useState([]);
    let [newcopyrequestdata, updatenewcopyRequestData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [inputvaluenew, setinputvaluenew] = useState('');
    const [filtervalue, setfiltervalue] = useState('');
    const [filterdate, setfilterdate] = useState(0);


    const [arrayid, setarrayid] = useState([]);
    const [pending, setPending] = React.useState(true);

    useEffect(() => {
        fecthdata();
    }, []);

    const fecthdata = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            fb_account_id: "trigvent.ynr",
        };
        axios
            .post(`${SENT_FRIEND_REQUEST}`, data, {
                headers: headers,
            })
            .then((response) => {
                console.log(response.data.data)
                updateRequestData(response.data.data);
                updatenewcopyRequestData(response.data.data);
                setPending(false);
            })
            .catch((error) => {
                updateRequestData([]);
                setPending(false);
            });
    };
    const columns = [
        {
            name: 'Name',
            selector: 'request_fb_name',
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => <p class={row.status == 0 ? 'not_success_message' : 'success_message'}>{row.status == 0 ? 'Not accepted' : 'Accepted'}</p>,
            sortable: true,
        },
        {
            name: 'Message Sent',
            selector: 'message',
            sortable: true,
        },
        {
            name: 'Keyword',
            selector: 'keyword',
        },
        {
            name: 'Date',
            selector: (row) => <p> {moment(new Date(row.created_at)).format(
                "DD/MM/YYYY"
            )}</p>,
        },
    ];

    const handleRowSelected = useCallback(state => {
        var array = [];
        setSelectedRows(state.selectedRows);
        state.selectedRows.map(r => {
            array.push(r.id)
        })

        console.log(array);
        setarrayid(array)


    }, []);

    const contextActions = useMemo(() => {


    }, [requestdata, selectedRows, toggleCleared]);
    const searchinput = (data) => {

        var input_value = data.target.value;
        setinputvaluenew(input_value)
        var tempdata = newcopyrequestdata;
        var value, datevalue;

        if (filtervalue != '' && filterdate !== 0) {
            if (filtervalue == 'not-accepted-only') {
                value = 0;
            }
            else {
                value = 1;
            }
            if (filterdate == "2_weeks") {
                var twoWeeks = 1000 * 60 * 60 * 24 * 14;
                var twoWeeksTime = new Date(new Date().getTime() - twoWeeks);
                datevalue = twoWeeksTime;
            } else if (filterdate == "1_month") {
                var curr = new Date();
                var onemonth = new Date(curr).setMonth(curr.getMonth() - 1);
                datevalue = onemonth;
            }
            else if (filterdate == "2_month") {
                var curr = new Date();
                var twomonth = new Date(curr).setMonth(curr.getMonth() - 2);
                datevalue = twomonth;
            }
            else if (filterdate == "6_month") {
                var curr = new Date();
                var sixmonthTime = new Date(curr).setMonth(curr.getMonth() - 6);
                datevalue = sixmonthTime;
            }
            else if (filterdate == "1_year") {
                var curr = new Date();
                var oneyear = new Date(curr).setMonth(curr.getMonth() - 12);
                datevalue = oneyear;
            }
            console.log(value)
            console.log(datevalue)

            tempdata = tempdata.filter(item =>
                new Date(item.created_at).getTime() <= datevalue && item.status == value && item.request_fb_name.toLowerCase().includes(input_value.toLowerCase())
            );
            console.log(tempdata)
        }
        else if (filtervalue != '') {
            if (filtervalue == 'not-accepted-only') {
                value = 0;
            }
            else {
                value = 1;
            }
            tempdata = tempdata.filter(item =>
                item.status == value && item.request_fb_name.toLowerCase().includes(input_value.toLowerCase())
            );
            console.log(tempdata)
        }
        if (filterdate !== 0) {
            if (filterdate == "2_weeks") {
                var twoWeeks = 1000 * 60 * 60 * 24 * 14;
                var twoWeeksTime = new Date(new Date().getTime() - twoWeeks);
                datevalue = twoWeeksTime;
            } else if (filterdate == "1_month") {
                var curr = new Date();
                var onemonth = new Date(curr).setMonth(curr.getMonth() - 1);
                datevalue = onemonth;
            }
            else if (filterdate == "2_month") {
                var curr = new Date();
                var twomonth = new Date(curr).setMonth(curr.getMonth() - 2);
                datevalue = twomonth;
            }
            else if (filterdate == "6_month") {
                var curr = new Date();
                var sixmonthTime = new Date(curr).setMonth(curr.getMonth() - 6);
                datevalue = sixmonthTime;
            }
            else if (filterdate == "1_year") {
                var curr = new Date();
                var oneyear = new Date(curr).setMonth(curr.getMonth() - 12);
                datevalue = oneyear;
            }
            console.log(value)
            console.log(datevalue)

            tempdata = tempdata.filter(item =>
                new Date(item.created_at).getTime() <= datevalue && item.request_fb_name.toLowerCase().includes(input_value.toLowerCase())
            );
            console.log(tempdata)
        }
        else {
            tempdata = tempdata.filter(item =>
                item.request_fb_name.toLowerCase().includes(input_value.toLowerCase())
            );
        }

        if (tempdata.length == 0) {
            updateRequestData([]);
        } else {
            updateRequestData(tempdata);
        }
        forceUpdate();
        // }
    };
    const referesh = () => {
        fecthdata();
        // setToggleClearRows(!toggledClearRows);
    }

    const handleClearRows = () => {
        console.log(authUser.token);
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#572F5B",
            cancelButtonColor: "#D9D9D9",
            confirmButtonText: "Yes,delete",
        }).then((result) => {
            if (result.isConfirmed) {
                // updateTagarray((current) => current.filter((array) => array.id !== id));
                deleteApi(arrayid);
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }
        });
    }
    const deleteApi = (arrayid) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            ids: arrayid,
        };
        axios
            .post(SENT_FRIEND_REQUEST_DELETE, data, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.status == 200) {
                    toast.success(response.data.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    fecthdata();
                } else {
                    toast.error(response.data.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => { });
    };

    const options = [
        {
            label: "Any Status",
            value: "any-status",
        },
        {
            label: "Accepted Only",
            value: "accepted_only",
        },
        {
            label: "Not Accepted Only",
            value: "not-accepted-only",
        },
    ];



    const options2 = [
        {
            label: "All Sent Friend Request",
            value: "all",
        },
        {
            label: "Older then 2 weeks",
            value: "2_weeks",
        },
        {
            label: "Older then 1 month",
            value: "1_month",
        },
        {
            label: "Older then 2 month",
            value: "2_month",
        },
        {
            label: "Older then 6 month",
            value: "6_month",
        },
        {
            label: "1 Year",
            value: "1_year",
        },
    ];

    const sortmessage = (data) => {
        var tempdata = newcopyrequestdata;
        var value, datevalue;
        console.log(data[0].value);


        if (inputvaluenew != '' && filterdate !== 0) {
            if (filterdate == "2_weeks") {
                var twoWeeks = 1000 * 60 * 60 * 24 * 14;
                var twoWeeksTime = new Date(new Date().getTime() - twoWeeks);
                datevalue = twoWeeksTime;
            } else if (filterdate == "1_month") {
                var curr = new Date();
                var onemonth = new Date(curr).setMonth(curr.getMonth() - 1);
                datevalue = onemonth;
            }
            else if (filterdate == "2_month") {
                var curr = new Date();
                var twomonth = new Date(curr).setMonth(curr.getMonth() - 2);
                datevalue = twomonth;
            }
            else if (filterdate == "6_month") {
                var curr = new Date();
                var sixmonthTime = new Date(curr).setMonth(curr.getMonth() - 6);
                datevalue = sixmonthTime;
            }
            else if (filterdate == "1_year") {
                var curr = new Date();
                var oneyear = new Date(curr).setMonth(curr.getMonth() - 12);
                datevalue = oneyear;
            }
            console.log(value)
            console.log(datevalue)

            tempdata = tempdata.filter(item =>
                new Date(item.created_at).getTime() <= datevalue && item.request_fb_name.toLowerCase().includes(inputvaluenew.toLowerCase())
            );
            if (data[0].value == "accepted_only") {
                setfiltervalue('accepted_only');
                value = 1;
                tempdata = tempdata.filter(item => item.status == 1);
            } else if (data[0].value == "not-accepted-only") {
                setfiltervalue('not-accepted-only');
                tempdata = tempdata.filter(item => item.status == 0);
                value = 1;
            } else {
                setfiltervalue(null);
                tempdata = newcopyrequestdata;
            }
        }
        else if (filterdate !== 0) {
            if (filterdate == "2_weeks") {
                var twoWeeks = 1000 * 60 * 60 * 24 * 14;
                var twoWeeksTime = new Date(new Date().getTime() - twoWeeks);
                datevalue = twoWeeksTime;
            } else if (filterdate == "1_month") {
                var curr = new Date();
                var onemonth = new Date(curr).setMonth(curr.getMonth() - 1);
                datevalue = onemonth;
            }
            else if (filterdate == "2_month") {
                var curr = new Date();
                var twomonth = new Date(curr).setMonth(curr.getMonth() - 2);
                datevalue = twomonth;
            }
            else if (filterdate == "6_month") {
                var curr = new Date();
                var sixmonthTime = new Date(curr).setMonth(curr.getMonth() - 6);
                datevalue = sixmonthTime;
            }
            else if (filterdate == "1_year") {
                var curr = new Date();
                var oneyear = new Date(curr).setMonth(curr.getMonth() - 12);
                datevalue = oneyear;
            }
            console.log(value)
            console.log(datevalue)

            tempdata = tempdata.filter(item =>
                new Date(item.created_at).getTime() <= datevalue
            );
            if (data[0].value == "accepted_only") {
                setfiltervalue('accepted_only');
                value = 1;
                tempdata = tempdata.filter(item => item.status == 1);
            } else if (data[0].value == "not-accepted-only") {
                setfiltervalue('not-accepted-only');
                tempdata = tempdata.filter(item => item.status == 0);
                value = 1;
            } else {
                setfiltervalue(null);
                tempdata = newcopyrequestdata;
            }
        }
        else if (inputvaluenew != '') {
            tempdata = tempdata.filter(item =>
                item.request_fb_name.toLowerCase().includes(inputvaluenew.toLowerCase())
            );
            if (data[0].value == "accepted_only") {
                setfiltervalue('accepted_only');
                value = 1;
                tempdata = tempdata.filter(item => item.status == 1);
            } else if (data[0].value == "not-accepted-only") {
                setfiltervalue('not-accepted-only');
                tempdata = tempdata.filter(item => item.status == 0);
                value = 1;
            } else {
                setfiltervalue(null);
                tempdata = newcopyrequestdata;
            }
        }
        else {
            if (data[0].value == "accepted_only") {
                setfiltervalue('accepted_only');
                value = 1;
                tempdata = tempdata.filter(item => item.status == 1);
            } else if (data[0].value == "not-accepted-only") {
                setfiltervalue('not-accepted-only');
                tempdata = tempdata.filter(item => item.status == 0);
                value = 1;
            } else {
                setfiltervalue(null);
                tempdata = newcopyrequestdata;
            }
        }
        updateRequestData(tempdata);

    };
    const sortmessagedatefilter = (data) => {
        var tempdata = newcopyrequestdata;
        if (filtervalue != '' && inputvaluenew != '') {
            var value;
            if (filtervalue == 'not-accepted-only') {
                value = 0;
            }
            else if (filtervalue == 'accepted_only') {
                value = 1;
            }
            else {
                value = '';
            }
            tempdata = tempdata.filter((item) =>
                item.request_fb_name.toLowerCase().includes(inputvaluenew.toLowerCase()) && item.status == value)

            if (data[0].value == "2_weeks") {
                var twoWeeks = 1000 * 60 * 60 * 24 * 14;
                setfilterdate("2_weeks");
                var twoWeeksTime = new Date(new Date().getTime() - twoWeeks);
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= twoWeeksTime
                );
                console.log(tempdata)
            } else if (data[0].value == "1_month") {
                var curr = new Date();
                var onemonth = new Date(curr).setMonth(curr.getMonth() - 1);
                setfilterdate("1_month");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= onemonth
                );

            }
            else if (data[0].value == "2_month") {
                var curr = new Date();
                var twomonth = new Date(curr).setMonth(curr.getMonth() - 2);
                setfilterdate("2_month");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= twomonth
                );

            }
            else if (data[0].value == "6_month") {
                var curr = new Date();
                var sixmonthTime = new Date(curr).setMonth(curr.getMonth() - 6);
                setfilterdate("6_month");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= sixmonthTime
                );
            }
            else if (data[0].value == "1_year") {
                var curr = new Date();
                var oneyear = new Date(curr).setMonth(curr.getMonth() - 12);
                setfilterdate("1_year");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= oneyear
                );
            } else {
                setfilterdate('');
                tempdata = newcopyrequestdata;
            }
        }
        else if (filtervalue != '') {
            var value;
            if (filtervalue == 'not-accepted-only') {
                value = 0;
            }
            else if (filtervalue == 'accepted_only') {
                value = 1;
            }
            else {
                value = '';
            }
            tempdata = tempdata.filter((item) =>
                item.status == value)

            if (data[0].value == "2_weeks") {
                var twoWeeks = 1000 * 60 * 60 * 24 * 14;
                setfilterdate("2_weeks");
                var twoWeeksTime = new Date(new Date().getTime() - twoWeeks);
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= twoWeeksTime
                );
                console.log(tempdata)
            } else if (data[0].value == "1_month") {
                var curr = new Date();
                var onemonth = new Date(curr).setMonth(curr.getMonth() - 1);
                setfilterdate("1_month");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= onemonth
                );

            }
            else if (data[0].value == "2_month") {
                var curr = new Date();
                var twomonth = new Date(curr).setMonth(curr.getMonth() - 2);
                setfilterdate("2_month");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= twomonth
                );

            }
            else if (data[0].value == "6_month") {
                var curr = new Date();
                var sixmonthTime = new Date(curr).setMonth(curr.getMonth() - 6);
                setfilterdate("6_month");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= sixmonthTime
                );
            }
            else if (data[0].value == "1_year") {
                var curr = new Date();
                var oneyear = new Date(curr).setMonth(curr.getMonth() - 12);
                setfilterdate("1_year");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= oneyear
                );
            } else {
                setfilterdate('');
                tempdata = newcopyrequestdata;
            }
        }
        else if (inputvaluenew != '') {
            tempdata = tempdata.filter((item) =>
                item.request_fb_name.toLowerCase().includes(inputvaluenew.toLowerCase()))

            if (data[0].value == "2_weeks") {
                var twoWeeks = 1000 * 60 * 60 * 24 * 14;
                setfilterdate("2_weeks");
                var twoWeeksTime = new Date(new Date().getTime() - twoWeeks);
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= twoWeeksTime
                );
                console.log(tempdata)
            } else if (data[0].value == "1_month") {
                var curr = new Date();
                var onemonth = new Date(curr).setMonth(curr.getMonth() - 1);
                setfilterdate("1_month");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= onemonth
                );

            }
            else if (data[0].value == "2_month") {
                var curr = new Date();
                var twomonth = new Date(curr).setMonth(curr.getMonth() - 2);
                setfilterdate("2_month");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= twomonth
                );

            }
            else if (data[0].value == "6_month") {
                var curr = new Date();
                var sixmonthTime = new Date(curr).setMonth(curr.getMonth() - 6);
                setfilterdate("6_month");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= sixmonthTime
                );
            }
            else if (data[0].value == "1_year") {
                var curr = new Date();
                var oneyear = new Date(curr).setMonth(curr.getMonth() - 12);
                setfilterdate("1_year");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= oneyear
                );
            } else {
                setfilterdate('');
                tempdata = newcopyrequestdata;
            }
        }
        else {
            if (data[0].value == "2_weeks") {
                var twoWeeks = 1000 * 60 * 60 * 24 * 14;
                setfilterdate("2_weeks");
                var twoWeeksTime = new Date(new Date().getTime() - twoWeeks);
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= twoWeeksTime
                );
                console.log(tempdata)
            } else if (data[0].value == "1_month") {
                var curr = new Date();
                var onemonth = new Date(curr).setMonth(curr.getMonth() - 1);
                setfilterdate("1_month");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= onemonth
                );

            }
            else if (data[0].value == "2_month") {
                var curr = new Date();
                var twomonth = new Date(curr).setMonth(curr.getMonth() - 2);
                setfilterdate("2_month");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= twomonth
                );

            }
            else if (data[0].value == "6_month") {
                var curr = new Date();
                var sixmonthTime = new Date(curr).setMonth(curr.getMonth() - 6);
                setfilterdate("6_month");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= sixmonthTime
                );
            }
            else if (data[0].value == "1_year") {
                var curr = new Date();
                var oneyear = new Date(curr).setMonth(curr.getMonth() - 12);
                setfilterdate("1_year");
                tempdata = tempdata.filter(item =>
                    new Date(item.created_at).getTime() <= oneyear
                );
            } else {
                setfilterdate('');
                tempdata = newcopyrequestdata;
            }
        }


        updateRequestData(tempdata);

    };
    return (

        <div className="col-md-10 right_side">
            <ToastContainer />
            <div className="row head_name">
                <div className="col-md-3 left_col">
                    <h3>Sent friend requests</h3>
                </div>
                <div className="col-md-9 right_col align-items-center justify-content-center d-flex">
                    <div className="d-flex justify-content-end">
                        <div className="head_top search_field">
                            <div className="input-group">
                                <input type="text" className="form-control" onChange={(e) => searchinput(e)} placeholder="Search friend request" />
                                <button className="tag_search_button" >
                                    <i class="fa fa-search" aria-hidden="true"></i>

                                </button>
                            </div>
                        </div>
                        <div className="head_top">
                            <Select
                                className="dropdown_tag"
                                options={options}
                                onChange={(values) => sortmessage(values)}
                                placeholder="Any status"
                            />
                        </div>
                        <div className="head_top">
                            <Select
                                className="dropdown_tag"
                                options={options2}
                                onChange={(values) => sortmessagedatefilter(values)}
                                placeholder="Filter"
                            />
                        </div>
                        <div className="actions">
                            <a href="#"><img src="assets/images/refresh.png" onClick={referesh} />Refresh</a>
                        </div>
                        <div className="actions action_last_col">
                            <a href="#" onClick={arrayid.length > 0 ? handleClearRows : null}><img src="assets/images/delete.png" />Delete</a>
                        </div>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={requestdata}
                pagination
                highlightOnHover
                selectableRows
                contextActions={contextActions}
                progressComponent={<Loader />}
                progressPending={pending}
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
            />
        </div>





    );
}
