import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TEMPLATE_MESSAGE,
  UPDATE_TEMPLATE_MESSASGE,
  DELETE_TEMPLATE_MESSAGE,
  ADD_TEMPLATE_MESSAGE,
} from "_helpers/api";
import Loader from "_components/loader";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import { Editor  } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';
import Select from "react-dropdown-select";

import {EditorState, convertToRaw} from 'draft-js'
export default function TemplateMessage(props) {
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const [loader, setLoader] = useState(false);
  const [selectedinput, setSelectedinput] = useState("");
  const { user: authUser } = useSelector((x) => x.auth);
  const [template, setTemplate] = useState([]);
  const [newcopytemplatearray, setnewcopytemplatearray] = useState([]);
  const [selectedcheck, setSelectedcheck] = useState(0);
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
  const  [convertedContent, setConvertedContent] = useState(null);
  const handleShowUpdate = (e, tagdata) => {
    setTemplate(tagdata);

  };
  useEffect(() => {
    fecthdata();
  }, [props.templatedata.id]);

  const fecthdata = () => {
    setLoader(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    axios
      .get(`${TEMPLATE_MESSAGE}/${props.templatedata.id}`, {
        headers: headers,
      })
      .then((response) => {
        setLoader(false);
        if (response.data.status == 200) {
          console.log(response.templateMessageDetails)
          setTemplate(response.data.templateMessageDetails);
          setnewcopytemplatearray(response.data.templateMessageDetails)
        } else {
          setTemplate([]);
          setnewcopytemplatearray([])
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };
  const showmessage = (e, id, name) => {
    console.log(name);
    e.preventDefault();
    setSelectedcheck(id);
    setSelectedinput(name);
  };
  const handleValuemessage = (e) => {
    setSelectedinput(e.target.value);
    console.log(e.target.value)
  };

  const messageupdate = (e, id,template_id, i) => {
    template[i].message = selectedinput;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      template_id: template_id,
      message: selectedinput,
    };
    axios
      .post(`${UPDATE_TEMPLATE_MESSASGE}/${id}`, data, {
        headers: headers,
      })
      .then((response) => {
        setLoader(false);
        if (response.data.status == 200) {
          toast.success(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {});
      setSelectedcheck(0);
    setSelectedinput(null);
  };

 const deleteMessage = (e, id, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#572f5b",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setTemplate((current) => current.filter((array) => array.id !== id));
        deleteApi(id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const deleteApi = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    axios
      .get(DELETE_TEMPLATE_MESSAGE + "/" + id, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status == 200) {
          toast.success(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {});
  };
 
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  }
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }
  const savenewmessage = () => {
    if (convertToRaw(editorState.getCurrentContent()).blocks.length === 1 & convertToRaw(editorState.getCurrentContent()).blocks[0].text === '') {

      console.log("hello")

    }
    else{
      const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
     
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authUser.token}`,
      };
      const data = {
        template_id:props.templatedata.id,
        message: value,
      };
       axios
      .post(`${ADD_TEMPLATE_MESSAGE}`, data, {
        headers: headers,
      })
      .then((response) => {
        setLoader(false);
        if (response.data.status == 200) {
          toast.success(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fecthdata();
        } else {
          toast.error(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
      });
    }
  }

  const sorttemplate = (data) => {
    console.log(data[0].value);
    var tempdata = newcopytemplatearray;
    if (data[0].value == "A-Z") {
      tempdata.sort((a, b) => a.name.localeCompare(b.name));
    } else if (data[0].value == "Z-A") {
      tempdata.sort((a, b) => b.name.localeCompare(a.name));
    } else if (data[0].value == "oldest-first-asc") {
      tempdata.sort(function(a, b){ // mutate copy array
        return a.id - b.id
    })
    } else if (data[0].value == "newest-first-desc") {
      tempdata.sort(function(a, b){ // mutate copy array
        return b.id - a.id
    })
   

    } else {
      tempdata = newcopytemplatearray;
      console.log(tempdata);
    }

    forceUpdate();
    setTemplate(tempdata);
     console.log(tempdata)

    // forceUpdate();
  };

 
  const options = [
    
    {
      label: "Oldest first",
      value: "oldest-first-asc",
    },
    {
      label: "Newest first",
      value: "newest-first-desc",
    },
  ];

  const searchinput = (data) => {
    var input_value = data.target.value;
    if (input_value == "") {
      setTemplate(newcopytemplatearray);
      forceUpdate();
    } else {
      var tempdata = newcopytemplatearray;
      const filteredArray = tempdata.filter((item) =>
        item.message.toLowerCase().includes(input_value.toLowerCase())
      );
      if (filteredArray.length == 0) {
        setTemplate([]);
      } else {
        setTemplate(filteredArray);
      }
      forceUpdate();
    }
  };
 
  return (
    
    <div className="col-md-8">
       <ToastContainer />
      <div className="row head_name">
        <div className="col-md-12 right_col p-0">
          <div className="row">
            <div className="col-md-6 head_top d-flex align-items-center">
              <h4>  {props.templatedata.name} ({props.templatedata.countmessage_count})</h4>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-end justify-content-end">
                <div className="head_top search_field ">
                  <div className="input-group">
                    <input type="text" className="form-control"  onChange={(e) => searchinput(e)} placeholder="Search" />
                    <span className="input-group-text"><img src="assets/images/search_black.png" /></span>
                  </div>
                </div>
                <div className="head_top actions action_last_col">
                  <div className="dropdown">
                    <Select
                    className="dropdown_tag_new"
                    options={options}
                    onChange={(values) => sorttemplate(values)}
                  />
                  </div>
                </div>
                <div className="template_cols">
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <>
                <div className="col-11" style={{border: "2px solid #d9d6da",minHeight:"230px",background: "#f9f2fa",marginBottom:"30px",borderRadius:"13px",position:"relative"}}>
                  <div className="row">
                  <div className="col-10">
                  <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      />
              </div>
              <div className="col-2 left_border_column">
              <a href="#" className="new_add_button" onClick={savenewmessage}>
                <img src="assets/images/create_folder.png" /></a>
              </div>
              </div>
              </div>
            </>
          {template.length == 0 ? (
            <h4 style={{ textAlign: "center", marginTop: "15px" }}>No Message found</h4>
          ) : (
            
            <>
          
              {template.map((datatemplate, i) => (
                <div className="row template_message" key={i}>
                  <div className="col-11">
                    {selectedcheck === datatemplate.id ? (
                    <textarea className="form-control" onChange={handleValuemessage}  name="text" disabled={selectedcheck === datatemplate.id ? false : true}>{datatemplate.message}</textarea>
                    ):(
                      <p className="form-control" style={{minHeight:"55px"}}>{datatemplate.message}</p>
                    )}
                    </div>
                  <div className="col-1 d-flex align-items-center p-0">
                    {selectedcheck === datatemplate.id ? (
                      <a href="#" onClick={(e) => messageupdate(e, datatemplate.id, datatemplate.template_id, i)}><img src="assets/images/check.png" /></a>
                    ) : (
                      <a href="#" onClick={(e) => showmessage(e, datatemplate.id, datatemplate.message)}><img src="assets/images/create.png" /></a>

                    )}
                    <a href="#" onClick={(e) => deleteMessage(e, datatemplate.id, i)} ><img src="assets/images/delete_icon.png" /></a>
                  </div>
                </div>
              ))}
            </>
          )}

        </>
      )}

    </div>
  );
}
