import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authActions,primaryAction } from "_store";
import axios from "axios";
import {
	GET_USER_DATA,
	UPDATE_USER_PROFILE,
	IMAGE_URL
} from "_helpers/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Select from "react-dropdown-select";
import Loader from "_components/loader";
import { history } from '_helpers';

export default function Profile() {
	const dispatch = useDispatch();

	const { user: authUser } = useSelector((x) => x.auth);
	const [, updateState] = useState({});
	const forceUpdate = useCallback(() => updateState({}), []);
	const [userdata, updateUserdata] = useState({});
	const [loader, updateLoader] = useState(true);
	const [userformdata, updateUserformdata] = useState({
		"file":"",
		"imagePreviewUrl":'assets/images/profile.png',
	});
	const [userformdataname, updateUserformdataname] = useState('');
	const { primary: primaryid } = useSelector((x) => x.auth);

	useEffect(() => {
		fecthdata();
	}, []);

	const fecthdata = () => {

		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${authUser.token}`,
		};
		const data = {
			// fb_account_id: "trigvent.ynr",
		};
		axios
			.post(`${GET_USER_DATA}`, data, {
				headers: headers,
			})
			.then((response) => {
				updateLoader(false);
				console.log(response.data.UserDetails, 'data')
				updateUserdata(response.data.UserDetails)
			})
			.catch((error) => {
				updateLoader(false);
				updateUserdata({})
			});
	};
	
	const photoUpload = (e) => {
		e.preventDefault();
		const file = e.target.files[0];
		console.log(URL.createObjectURL(e.target.files[0]));
			updateUserformdata({
			file: file,
			imagePreviewUrl: URL.createObjectURL(e.target.files[0])
		  });
		
	  }

	  const namechange = e => {
        console.log(e.target.value)
		updateUserformdataname(e.target.value);
    }
	  const handleFormSubmit = (e, name) => {
        // e.preventDefault ()
		var username;
		if(userformdataname == '')
		{
			username = name;
		}
		else{
			username =userformdataname; 
		}
		// else{
            const formData = new FormData ()
			formData.append ('image', userformdata.file)
			formData.append ('name', username)
			const headers = {
				Authorization: `Bearer ${authUser.token}`,
			};
			const data = {
				// fb_account_id: "trigvent.ynr",
			};
			axios
				.post(`${UPDATE_USER_PROFILE}`, formData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.status == 200) {
						toast.success(response.data.msg, {
						  position: toast.POSITION.TOP_RIGHT,
						});
					  } else {
						toast.error(response.data.msg, {
						  position: toast.POSITION.TOP_RIGHT,
						});
					}
				})
				.catch((error) => {
				});
			//}
			
			}

			const getaccount = (e, primary_id) =>{
				dispatch(primaryAction.primary({actionId:primary_id}));
			  }
	return (

		<div className="col-md-10 right_side accounts_page">
			<ToastContainer />
			<div className="row head_name">
				<div className="col-md-7 left_col">
					<h3>Account settings</h3>
					<p>Here you can edit public information about yourself.</p><p> The changes will be displayed for other users.</p>
				</div>
				<div className="col-md-5 right_col align-items-center justify-content-end d-flex">
					<div className="d-flex justify-content-end">
						<div className="actions action_last_col">
							<a href="#"><img src="assets/images/add.png" />Add account</a>
						</div>
					</div>
				</div>
			</div>
			{/* {userdata.map((data, i) => ( */}
			<div className="row account_profile">
				<div className="col-md-5">
					<div className="profile_cols">
						<h3>Your profile</h3>
						
						<label htmlFor="photo-upload" className="custom-file-upload fas align-items-center justify-content-center mb-3 mt-2">
    <div className="img-wrap img-upload" >
      <img for="photo-upload"   onError="this.onerror=null;this.src='assets/images/profile.png'" className="upload-image-preview" src={userdata.image != null ? `${IMAGE_URL}images/${userdata.image}` : userformdata.imagePreviewUrl }/>
    </div>
    <input id="photo-upload" type="file"   onChange={photoUpload}/> 
  </label>
						<form>
							<div className="input-group mb-3">
								<span className="input-group-text"><img src="assets/images/status.png" /></span>
								<input type="text" className="form-control" defaultValue={userdata.name} placeholder="Team status" onChange={namechange}/>
							</div>
							<div className="input-group mb-3">
								<span className="input-group-text"><img src="assets/images/envelope.png" /></span>
								<input type="email" className="form-control" defaultValue={userdata.email} placeholder="Useremail@gmail.com" disabled/>
							</div>
							<div className="input-group">
								<span className="input-group-text"><img src="assets/images/icon.png" /></span>
								<input type="text" className="form-control" defaultValue={userdata.license_key} placeholder="Licence key" disabled/>
							</div>
						</form>
						<div className="row actions mt-3">
				<div className="col-md-12">
					<a href="#" className="save_btn" onClick={(e) => handleFormSubmit(e, userdata.name)} >Save</a>
				</div>
			</div>
					</div>
				</div>
				<div className="col-md-7">
					<div className="profile_cols">
						<h3>Team profile</h3>
						{loader === true ? (
							<Loader />
						) : (
							<div className="row">
								{
									Object.entries(userdata).length === 0 ? (
										<h4 style={{ textAlign: "center" }}>No data found</h4>
									)
										:
										(
											<>
												{
													userdata.linked_fb_accounts.map((account, i) => (
														<div className="col-md-4 team_Profile_cols_sec" key={i}>
															<div className={`team_Profile_cols ${primaryid == account.fb_account_id ? 'active' :''}`} onClick={(e) =>
																	getaccount(e, account.fb_account_id)
																}>
																<img src="assets/images/Avatar.png"  onClick={(e) => history.navigate('userdashboard',{ state: { name: account.fb_account_id } })}/>
																<h4 className={`h4_change ${primaryid == account.fb_account_id ? 'active' :''}`}>{account.fb_account_id}</h4>
																<img src={primaryid == account.fb_account_id ? 'assets/images/delete_white.png' : 'assets/images/trash.png'} />
															</div>
														</div>
													))
												}
											</>
										)
								}
							</div>
						)}
					</div>
				</div>
			</div>
			{/* ))} */}

			
		</div>
	)

}