import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { Row, Card, Col } from "react-bootstrap";
export { Instagram };

function Instagram() {
    const { user: authUser } = useSelector((x) => x.auth);


    // only show nav when logged in
    if (!authUser) return null;
    return (
        <>
            <Row>
                <Col md={3}>
                    <Card className="columns border-0">

                        <Row>
                            <Col md={12} className="d-flex justify-content-between">
                                <h3>Coming soon</h3>
                                {/* <img src="assets/images/arrow.png" /> */}
                            </Col>
                        </Row>
                    </Card>
                </Col>

            </Row>
        </>
    );
}
