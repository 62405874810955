import React, { useState, useEffect, useCallback } from "react";

import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authActions, primaryAction } from "_store";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { GET_USER_DATA } from "_helpers/api";
import Select from "react-dropdown-select";

export { Sidebar };

function Sidebar() {
  const authUser = useSelector((x) => x.auth.user);
  const dispatch = useDispatch();
  const logout = () => dispatch(authActions.logout());
  const [fbaccount, updatefbaccount] = useState([]);

  useEffect(() => {
    if (authUser) {
      fecthdata();
    }
  }, [authUser]);

  const fecthdata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      // fb_account_id: "trigvent.ynr",
    };
    axios
      .post(`${GET_USER_DATA}`, data, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data.UserDetails.linked_fb_accounts)
        updatefbaccount(response.data.UserDetails.linked_fb_accounts)
        // updateRequestData(response.data.data);
        // updatenewcopyRequestData(response.data.data);
        // setPending(false);
      })
      .catch((error) => {
        // updateRequestData([]);
        // setPending(false);
      });
  };

  // only show nav when logged in
  if (!authUser) return null;

  return (
    <div className="col-md-2 left_side">
      <ul className="navbar-nav">
        <li className="nav-item  ">
          <NavLink as={Link} className="nav-link" activeClassName="is-active" to="/">
            <img src="assets/images/dashboard.png" className="purple" />
            <img src="assets/images/dashboard_outline.png" className="white" />
            <span className="menu_links">Dashboard</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink as={Link} className="nav-link" activeClassName="is-active" to="/tags">
            <img src="assets/images/tags.png" className="purple" />
            <img src="assets/images/tags_outline.png" className="white" />
            <span className="menu_links">Tags</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink as={Link} className="nav-link" activeClassName="is-active" to="/template">
            <img src="assets/images/templates.png" className="purple" />
            <img src="assets/images/templates_outline.png" className="white" />
            <span className="menu_links">Template</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink as={Link} className="nav-link" activeClassName="is-active" to="/friendrequest">
            <img
              src="assets/images/send_friend_request.png"
              className="purple"
            />
            <img
              src="assets/images/send_friend_request_outline.png"
              className="white"
            />
            <span className="menu_links">Send friend request</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink as={Link} className="nav-link" activeClassName="is-active" to="/BulkMessage">
            <img src="assets/images/send_bulk.png" className="purple" />
            <img src="assets/images/send_bulk_outline.png" className="white" />
            <span className="menu_links">Send bulk messages</span>
          </NavLink>
        </li>
      </ul>
      <ul className="navbar-nav foot">
        <li className="nav-item">
          <a className="nav-link" href="#">
            <img src="assets/images/premium.png" />
          </a>
        </li>
      </ul>

    </div>
  );
}
