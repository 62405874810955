import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { history } from '_helpers';
import { TARGETED_FRIEND_REQUEST } from "_helpers/api";
import Dropdown from 'react-bootstrap/Dropdown';
import Loader from "_components/loader";
import moment from "moment";


// import {
//   GET_TAG_WITH_COUNTER,
//   CHATSILO_FRIEND_REQUEST_DATA
// } from "_helpers/api";
// import Loader from "_components/loader";
// import moment from "moment";
// import { history } from '_helpers';

export default function TargetedFriendRequest() {
    const { state } = useLocation();
    const { name, account } = state;
    const [targetArray, updateTargetArray] = useState({});
    const [status, updateStatus] = useState(true);
    const { user: authUser } = useSelector((x) => x.auth);

    useEffect(() => {
        if (name == "chatsilo") {
            fecthchatsilotaggedfriendrequestdata();
        }
    }, []);

    const fecthchatsilotaggedfriendrequestdata = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            fb_account_id: "trigvent.sol.1"
        };
        axios
            .post(`${TARGETED_FRIEND_REQUEST}`, data, {
                headers: headers,
            })
            .then((response) => {
                console.log(response);
                let finaldata = response.data.data;
                console.log(finaldata);
                updateTargetArray(finaldata);
                updateStatus(false);
            })
            .catch((error) => {
                updateStatus(false);
            });
    };


    console.log("YIPPPE", targetArray);
    return (
        <div className="col-md-10 right_side">
            <div className="row head_name">
                <div className="col-md-5 left_col">
                    <h3><img src="assets/images/arrow_back.png" onClick={(e) => history.navigate('userdashboard', { state: { name: account } })} /> Targeted Friend request ({targetArray.length})</h3>
                </div>

            </div>

            {status === true ? (<Loader />) : (
                <>
                    {
                        targetArray.map((targetdata, i) => (
                            <Dropdown className="target_friend_requests">
                                <Dropdown.Toggle id="dropdown-basic " className="target_friend_request">
                                    {moment(new Date(targetdata.dates)).format(
                                        "DD/MM/YYYY"
                                    )} ({targetdata.names.length})
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {targetdata.names.map((targetnames, i) => (
                                        <Dropdown.Item className="dropdownItem d-flex" > <div>
                                            <img src="assets/images/empty.png" alt="no" />
                                        </div><div> {targetnames.request_fb_name}</div>

                                        </Dropdown.Item>

                                    ))}
                                </Dropdown.Menu>


                            </Dropdown>
                        ))
                    }
                </>
            )}



        </div>
    );
}
