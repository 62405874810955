import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { SAVE_TAG } from "_helpers/api";
import Loader from "_components/loader";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

export default function SaveTagComponent(props) {
  const [show, setShow] = useState(false);
  const [selectedinput, setSelectedinput] = useState("");
  const { user: authUser } = useSelector((x) => x.auth);

  const randomColor = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "dark",
  ];
  const [randomNumber, setRandomNumber] = useState(0);
  const [validated, setValidated] = useState(false);
  const [errormsg, setErrorMsg] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleValue = (e) => {
    setSelectedinput(e.target.value);
    console.log(selectedinput);
  };

  const generateRandomNumber = (e) => {
    const randomNumber = Math.floor(Math.random() * randomColor.length);
    setRandomNumber(randomNumber);
    //console.log(randomColor[randomNumber]);
  };
  const saveTag = (e) => {
    generateRandomNumber();
    if (selectedinput == "") {
      setValidated(true);
    } else {
      setValidated(false);
      saveApi();
    }
  };

  const saveApi = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      name: selectedinput,
      class: randomColor[randomNumber],
      custom_color: "",
    };
    axios
      .post(SAVE_TAG, data, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status == 200) {
          toast.success(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          props.tagdata(response.data.newtag);
          setErrorMsg();
          handleClose();
        } else {
          setErrorMsg(response.data.msg);
          console.log(errormsg);
          //   toast.error(response.data.msg, {
          //     position: toast.POSITION.TOP_RIGHT,
          //   });
        }
      })
      .catch((error) => { });
  };

  return (
    <>
      <a href="#" className="add_tag" onClick={handleShow}>
        <img src="assets/images/add.png" />
        add new tag
      </a>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ border: 0 }}>
          <Modal.Title>ADD TAG</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Tag Name</Form.Label>
              <Form.Control
                type="text"
                className={
                  validated ? "redBordervalidationerror" : "originalClass"
                }
                placeholder="Enter Tag Name"
                autoFocus
                onChange={(e) => handleValue(e)}
              />
              {errormsg != "" ? (
                <h6 style={{ color: "red" }}>{errormsg}</h6>
              ) : (
                ""
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ border: 0 }}>
          <a
            href="#"
            className="add_tag button_global"
            onClick={(e) => saveTag(e)}
          >
            <img src="assets/images/add.png" />
            Add
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
}
