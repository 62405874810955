import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FacebookTab } from "home/profile/facebooktab";
import { Instagram } from "_components/dashboard/instagram";
import { Twitter } from "_components/dashboard/twitter";
// import { authActions,primaryAction } from "_store";
// import axios from "axios";
// import {
// 	GET_USER_DATA,
// 	UPDATE_USER_PROFILE,
// 	IMAGE_URL
// } from "_helpers/api";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Swal from "sweetalert2";
// import Select from "react-dropdown-select";
// import Loader from "_components/loader";
// import { history } from '_helpers';

export default function UserDashboard() {
  const { state } = useLocation();
  const { name } = state;
  console.log(name)
  return (
    <div class="col-md-10 right_side">
      <div class="row head_name">
        <div class="col-md-3 left_col">
          <h3>{name}</h3>
        </div>
      </div>
      <Tabs
        defaultActiveKey="Facebook"
        id="uncontrolled-tab-example"
        className="nav nav-tabs"
      >
        <Tab eventKey="Facebook" title="Facebook" className="nav-item">
          <FacebookTab fbaccount={name} />
        </Tab>
        <Tab eventKey="Instagram" title="Instagram" className="nav-item">
          <Instagram />
        </Tab>
        <Tab eventKey="Twitter" title="Twitter" className="nav-item">
          <Twitter />
        </Tab>
      </Tabs>
    </div>
  )

}