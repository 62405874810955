import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import {
  GET_TAG_WITH_COUNTER,
} from "_helpers/api";
import Loader from "_components/loader";
import { history } from '_helpers';

export default function TagsData() {
    const {state} = useLocation();
    const { name, account } = state;
    const [status, updateStatus] = useState(true);
    const [tagarray, updateTagarray] = useState([]);
    const { user: authUser } = useSelector((x) => x.auth);

  useEffect(() => {
    if(name == "chatsilo")
    {
      fecthchatsilotagdata();
    }
  }, []);

  const fecthchatsilotagdata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      fb_account_id: account,
    };
    axios
      .post(`${GET_TAG_WITH_COUNTER}`, data, {
        headers: headers,
      })
      .then((response) => {
        let finaldata = response.data.tagDetails;
        updateTagarray(finaldata);
        updateStatus(false);
      })
      .catch((error) => {
        updateStatus(false);
      });
  };
 

  return (
    <div className="col-md-10">
      <div className="row">
        <div className="col-md-12 right_side">
          <div className="row head_name">
            <div className="col-md-3 left_col">
            <h3><img src="assets/images/arrow_back.png" onClick={(e) => history.navigate('userdashboard',{ state: { name: account} })}/>  Tags ({tagarray.length})</h3>

            </div>
          </div>
          {status === true ? (
            <Loader />
          ) : (
            <>
              <div className="row tags_sec">
                {tagarray.length == 0 ? (
                  <h4 style={{ textAlign: "center" }}>No data found</h4>
                ) : (
                  <>
                    {tagarray.map((tagdata, i) => (
                      // {data.map((tagdata, i) => {
                      //   return (
                      <div className="col-2 tag_col_sec" key={i}>
                        <div
                          className={`tag_cols bg-${tagdata.class}`}
                          style={{
                            "--bs-bg-opacity": 0.2,
                            minHeight:"50px"
                          }}
                        > <h2 style={{fontSize:"12px"}}>
                              {tagdata.name} ({tagdata.users})
                            </h2>
                        </div>
                      </div>
                      // );
                    ))}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
