import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import {
  GET_TAG_WITH_COUNTER,
  CHATSILO_FRIEND_REQUEST_DATA
} from "_helpers/api";
import Loader from "_components/loader";
import moment from "moment";
import { history } from '_helpers';

export default function SendRequest() {
  const { state } = useLocation();
  const { name, account } = state;
  const [status, updateStatus] = useState(true);
  const [tagarray, updateTagarray] = useState({});
  const { user: authUser } = useSelector((x) => x.auth);

  useEffect(() => {
    if (name == "chatsilo") {
      fecthchatsilotagdata();
    }
  }, []);

  const fecthchatsilotagdata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      //process_id: 988746546,
      type: 1,
      fb_account_id: "trigvent.sol.1"
    };
    axios
      .post(`${CHATSILO_FRIEND_REQUEST_DATA}`, data, {
        headers: headers,
      })
      .then((response) => {
        console.log(response);
        let finaldata = response.data.data;
        console.log(finaldata);
        updateTagarray(finaldata);


        updateStatus(false);
      })
      .catch((error) => {
        updateStatus(false);
      });
  };

  console.log(tagarray);
  return (
    <div className="col-md-10">
      <div className="row">
        <div className="col-md-12 right_side">
          <div className="row head_name">
            <div className="col-md-5 left_col">
              <h3><img src="assets/images/arrow_back.png" onClick={(e) => history.navigate('userdashboard', { state: { name: account } })} />  Friend request ({tagarray.length})</h3>
            </div>
          </div>
          {status === true ? (
            <Loader />
          ) : (
            <>
              <div className="row tags_sec">
                {tagarray.length === 0 ? (
                  <h4 style={{ textAlign: "center" }}>No data found</h4>
                ) : (
                  <>
                    <div class="row bulk_message_cols account_page">
                      {tagarray.map((tagdata, i) => (
                        <div class="message_sec">
                          <p>{tagdata.message}</p>
                          <span>{moment(new Date(tagdata.created_at)).format(
                            "DD/MM/YYYY"
                          )}</span>
                          <span>{tagdata.sent} sent</span>
                          <span>{tagdata.error} failed</span>
                        </div>
                        // );
                      ))}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
