import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { history } from "_helpers";
import { authActions } from "_store";
import "bootstrap/dist/css/bootstrap.min.css";

export { Login };

function Login() {
  const dispatch = useDispatch();
  const authUser = useSelector((x) => x.auth.user);
  const authError = useSelector((x) => x.auth.error);

  useEffect(() => {
    // redirect to home if already logged in
    if (authUser) history.navigate("/");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  function onSubmit({ email, password }) {
    return dispatch(authActions.login({ email, password }));
  }

  return (
    // <div className="col-md-6 offset-md-3 mt-5">
    //   <div className="alert alert-info">
    //     Username: test
    //     <br />
    //     Password: test
    //   </div>
    //   <div className="card">
    //     <h4 className="card-header">Login</h4>
    //     <div className="card-body">
    //       <form onSubmit={handleSubmit(onSubmit)}>
    //         <div className="form-group">
    //           <label>Username</label>
    //           <input
    //             name="email"
    //             type="email"
    //             {...register("email")}
    //             className={`form-control ${errors.email ? "is-invalid" : ""}`}
    //           />
    //           <div className="invalid-feedback">{errors.email?.message}</div>
    //         </div>
    //         <div className="form-group">
    //           <label>Password</label>
    //           <input
    //             name="password"
    //             type="password"
    //             {...register("password")}
    //             className={`form-control ${
    //               errors.password ? "is-invalid" : ""
    //             }`}
    //           />
    //           <div className="invalid-feedback">{errors.password?.message}</div>
    //         </div>
    //         <button disabled={isSubmitting} className="btn btn-primary">
    //           {isSubmitting && (
    //             <span className="spinner-border spinner-border-sm mr-1"></span>
    //           )}
    //           Login
    //         </button>
    //         {authError && (
    //           <div className="alert alert-danger mt-3 mb-0">{authError}</div>
    //         )}
    //       </form>
    //     </div>
    //   </div>
    // </div>

    <div className="row login_pages">
      <div className="col-md-12 text-center mt-5">
        <img src="assets/images/chatsilo_login_icon.png" alt="" />
      </div>
      <div className="row">
        <div className="col-md-4"></div>

        <div className="col-md-4 p-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login_box">
              <h3>Welcome Back</h3>
              <p>Enter your Login credentials to access your account.</p>
              <div className="input-group mt-3 mb-3">
                <span className="input-group-text"><img src="assets/images/envelope.png" alt=""/></span>
                <input name="email" type="email" className={`form-control ${errors.email ? "is-invalid" : ""}`} placeholder="Enter your Email"  {...register("email")} />
                <div className="invalid-feedback">{errors.email?.message}</div>
              </div>
              <div className="input-group mt-3 mb-3">
                <span className="input-group-text"><img src="assets/images/key.png" alt="" /></span>
                <input name="password" type="password"  {...register("password")} className={`form-control ${errors.password ? "is-invalid" : ""}`} placeholder="Enter your Password" />
                <div className="invalid-feedback">{errors.password?.message}</div>
              </div>
              <div className="col-md-12 d-grid">
                <button disabled={isSubmitting} className="btn btn-primary">{isSubmitting && (<span className="spinner-border spinner-border-sm mr-1"></span>)} Sign in</button>
                {authError && (<div className="alert alert-danger mt-3 mb-0">{authError}</div>)}
              </div>
            </div>
            <ul className="links">
              <NavLink as={Link} className="nav-link" activeClassName="is-active" to="/forgot"> Forgot password </NavLink>
              {/* <a href="forgotPassword">Forgot your License Key?</a> */}
              {/* <NavLink as={Link} className="nav-link" activeClassName="is-active" to="/forgot"> Get Support </NavLink> */}
              {/* <a href="#" className="support">Get Support</a> */}
            </ul>
          </form>
        </div>
      </div>
    </div >


  );
}
