import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Facebook } from "_components/dashboard/facebook";
import axios from "axios";
import {
  GET_TAG_WITH_COUNTER,
  UPDATE_TAG,
  DELETE_TAG,
  UPDATE_TAG_COLOR,
  EXPORT_TAG,
} from "_helpers/api";
import Loader from "_components/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import SaveTagComponent from "./savetag";
import ImportTag from "./importtag";
import ContactTag from "./tagcontact";
import Select from "react-dropdown-select";
import { HexColorPicker } from "react-colorful";
import Tooltip from '@mui/material/Tooltip';

export default function Tags() {
  // const dispatch = useDispatch();
  // const { user: authUser } = useSelector((x) => x.auth);
  // //const { users } = useSelector((x) => x.users);
  const primaryIds = useSelector((x) => x.auth.primary);

  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  let [status, updateStatus] = useState(true);
  const [selected, setSelected] = useState(0);
  const [selectedinput, setSelectedinput] = useState(null);
  const [selectedcheck, setSelectedcheck] = useState(0);
  const { user: authUser } = useSelector((x) => x.auth);
  const { primary: primaryid } = useSelector((x) => x.auth);

  let inputRef = useRef(null);

  let [tagarray, updateTagarray] = useState([]);
  let [checkcontanct, setCheckcontanct] = useState(true);
  let [groupcontact, setGroupcontact] = useState([]);

  let [newcopytagarray, updateNewcopytagarray] = useState([]);
  const [open, setopen] = useState(false);
  const [color, setcolor] = useState({});
  const [colorpickerselect, setcolorpickerselect] = useState(0);

  useEffect(() => {
    fecthdata();
    //const { users } = useSelector((x) => x.users);
    console.log("i fire once");
  }, [primaryIds]);

  const fecthdata = () => {
    console.log(primaryIds)
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      fb_account_id: primaryid,
    };
    axios
      .post(`${GET_TAG_WITH_COUNTER}`, data, {
        headers: headers,
      })
      .then((response) => {
        let finaldata = response.data.tagDetails;
        updateTagarray(finaldata);
        updateNewcopytagarray(finaldata);
        updateStatus(false);
      })
      .catch((error) => {
        updateStatus(false);
      });
  };
  const getInput = (e, id, name) => {

    e.preventDefault();
    setSelected(id);
    setSelectedcheck(id);
    setSelectedinput(name);
    setTimeout(() => {
      console.log(inputRef);
      inputRef.current.focus();
    }, 500);
  };
  const handleValue = (e) => {
    setSelectedinput(e.target.value);
  };
  const editTag = (e, id, index) => {
    //setSelectedinput("");
    console.log(selectedinput);

    tagarray[index].name = selectedinput;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      name: selectedinput,
    };
    axios
      .post(UPDATE_TAG + "/" + id, data, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status == 200) {
          toast.success(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // setSelected(0);
          // setSelectedcheck(0);
          // setSelectedinput("");
        } else {
          toast.error(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        updateStatus(false);
      });
    setSelected(0);
    setSelectedcheck(0);
    setSelectedinput(null);
  };

  const deleteTag = (e, id, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#572F5B",
      cancelButtonColor: "#D9D9D9",
      confirmButtonText: "Yes,delete",
    }).then((result) => {
      if (result.isConfirmed) {
        updateTagarray((current) => current.filter((array) => array.id !== id));
        deleteApi(id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const deleteApi = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    // const data = {
    //   id: selectedinput,
    // };
    axios
      .get(DELETE_TAG + "/" + id, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status == 200) {
          toast.success(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => { });
  };

  const newtagdata = (data) => {
    var tempdata = tagarray;

    tempdata.push(data);
    console.log(data);
    updateTagarray(tempdata);
    forceUpdate();
    //
  };

  const exceltagdata = (data) => {
    fecthdata();
    // var tempdata = data;
    // console.log(data);
    // updateTagarray(tempdata);
    // forceUpdate();
    //
  };

  const sorttag = (data) => {
    console.log(data[0].value);
    var tempdata = tagarray;
    if (data[0].value == "A-Z") {
      tempdata.sort((a, b) => a.name.localeCompare(b.name));
    } else if (data[0].value == "Z-A") {
      tempdata.sort((a, b) => b.name.localeCompare(a.name));
    } else if (data[0].value == "oldest-first-asc") {
      tempdata.sort((a, b) => a.id - b.id);
    } else if (data[0].value == "newest-first-desc") {
      tempdata.sort((a, b) => b.id - a.id);
    } else {
      tempdata = tagarray;
      console.log(tempdata);
    }
    updateTagarray(tempdata);
    forceUpdate();
  };

  const searchinput = (data) => {
    var input_value = data.target.value;
    if (input_value == "") {
      updateTagarray(newcopytagarray);
      forceUpdate();
    } else {
      var tempdata = newcopytagarray;
      const filteredArray = tempdata.filter((item) =>
        item.name.toLowerCase().includes(input_value.toLowerCase())
      );
      if (filteredArray.length == 0) {
        updateTagarray([]);
      } else {
        updateTagarray(filteredArray);
      }
      forceUpdate();
    }
  };
  const openBox = (e, id, index) => {
    setcolorpickerselect(id);
    setopen(true);
  };
  const closecolorpicker = (e, id, index) => {
    setcolorpickerselect(0);
    setopen(false);
  };
  const changeTagcolor = (e, id, index) => {
    var setcolor = e;
    tagarray[index].custom_color = setcolor;
    forceUpdate();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      color: setcolor,
    };
    axios
      .post(UPDATE_TAG_COLOR + "/" + id, data, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status == 200) {
        } else {
        }
      })
      .catch((error) => { });
  };
  const options = [
    {
      label: "By A-Z",
      value: "A-Z",
    },
    {
      label: "By Z-A",
      value: "Z-A",
    },
    {
      label: "Oldest first",
      value: "oldest-first-asc",
    },
    {
      label: "Newest first",
      value: "newest-first-desc",
    },
  ];

  const setColor = () => {
    //setcolor.push
    setopen(true);
  };

  const exportTag = () => {
    const headers = {
      "Content-Type": "application/json",
      //"Content-Disposition": "attachment;filename=report.csv",
      //"Content-Type": "application/octet-stream", // or Content-type: "application/vnd.ms-excel"
      Authorization: `Bearer ${authUser.token}`,
    };
    axios({
      url: `${EXPORT_TAG}`,
      method: "Post",
      responseType: "blob",
      headers: headers, // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  const checkGroupcontact = (e, data) => {
    // setCheckcontanct(false);
    // setGroupcontact([]);
    setCheckcontanct(true);
    setGroupcontact(data);
    forceUpdate();
  };

  const updatepopupback = () => {
    fecthdata();
  };

  return (


    <div className="col-md-10">
      <div className="row">
        <div className="col-md-9 right_side">
          <ToastContainer />
          <div className="row head_name">
            <div className="col-md-2 left_col">
              <h3>Tags</h3>
            </div>
            <div className="col-md-10 right_col align-items-center justify-content-center d-flex">
              <div className="d-flex justify-content-end">
                <div className="head_top upload">
                  <ImportTag tagdata={exceltagdata} />
                </div>
                <div className="head_top upload">
                  <a href="#" onClick={(e) => exportTag(e)}>
                    <img src="assets/images/Login.png" />
                  </a>
                </div>
                <div className="head_top search_field">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search tags"
                      onChange={(e) => searchinput(e)}
                    />
                    <button className="tag_search_button" >
                      <i class="fa fa-search" aria-hidden="true"></i>

                    </button>

                  </div>
                </div>
                <div className="head_top">
                  <div className="dropdown">
                    <Select
                      className="dropdown_tag"
                      placeholder="Sort Tags"
                      options={options}
                      onChange={(values) => sorttag(values)}
                    />
                  </div>
                </div>
                <div className="actions action_last_col">
                  <SaveTagComponent tagdata={newtagdata} />
                </div>
              </div>
            </div>
          </div>
          {status === true ? (
            <Loader />
          ) : (
            <>
              <div className="row tags_sec">
                {tagarray.length == 0 ? (
                  <h4 style={{ textAlign: "center" }}>No data found</h4>
                ) : (
                  <>
                    {tagarray.map((tagdata, i) => (
                      // {data.map((tagdata, i) => {
                      //   return (
                      <div onClick={(e) => checkGroupcontact(e, tagdata)} className="col-2 tag_col_sec" key={i} style={{ cursor: "pointer", }}>
                        <div
                          className={`tag_cols bg-${tagdata.class}`}
                          style={{
                            "--bs-bg-opacity": 0.2,
                          }}
                          ref={(el) => {
                            if (el) {
                              el.style.setProperty(
                                "background",
                                `${tagdata.custom_color + "40"}`,
                                "important"
                              );
                            }
                          }}
                        >
                          {selected === tagdata.id ? (
                            <input
                              ref={inputRef}
                              defaultValue={tagdata.name}
                              type="text"
                              onChange={(e) => handleValue(e)}
                              onBlur={(e) =>
                                editTag(e, tagdata.id, i, tagdata.name)
                              }
                              className="form-control tags_name_input"
                              style={{ background: "none", border: "none" }}
                              readOnly={selected === tagdata.id ? false : true}
                            />
                          ) : (
                            <h2>
                              {console.log(tagdata.users)}
                              {tagdata.name} ({tagdata.users === undefined ? 0 : tagdata.users})
                            </h2>
                          )}
                          <div className="tag_icons">


                            <span
                              className={`action_icons first bg-${tagdata.class}`}
                              ref={(el) => {
                                if (el) {
                                  el.style.setProperty(
                                    "background",
                                    `${tagdata.custom_color}`,
                                    "important"
                                  );
                                }
                              }}
                              onClick={(e) => checkGroupcontact(e, tagdata)}
                            >
                              <img title="Mail" src="assets/images/mail.png" />
                            </span>



                            {selectedcheck === tagdata.id ? (
                              <span
                                className={`action_icons bg-${tagdata.class}`}
                                ref={(el) => {
                                  if (el) {
                                    el.style.setProperty(
                                      "background",
                                      `${tagdata.custom_color}`,
                                      "important"
                                    );
                                  }
                                }}
                              // onClick={(e) =>
                              //   editTag(e, tagdata.id, i, tagdata.name)
                              // }
                              >
                                <img src="assets/images/edit.png" />
                              </span>

                            ) : (
                              <span
                                className={`action_icons bg-${tagdata.class}`}
                                ref={(el) => {
                                  if (el) {
                                    el.style.setProperty(
                                      "background",
                                      `${tagdata.custom_color}`,
                                      "important"
                                    );
                                  }
                                }}
                                onClick={(e) =>
                                  getInput(e, tagdata.id, tagdata.name)
                                }
                              >
                                <img title="Edit" src="assets/images/edit.png" />
                              </span>
                            )}



                            <span
                              onClick={(e) => deleteTag(e, tagdata.id, i)}
                              ref={(el) => {
                                if (el) {
                                  el.style.setProperty(
                                    "background",
                                    `${tagdata.custom_color}`,
                                    "important"
                                  );
                                }
                              }}
                              className={`action_icons bg-${tagdata.class}`}
                            >
                              <img title="Delete" src="assets/images/delete.png" />
                            </span>

                            {colorpickerselect === tagdata.id ? (
                              <span
                                onClick={() => closecolorpicker()}
                                className={`action_icons bg-${tagdata.class}`}
                                style={{ background: "#66B718" }}
                                ref={(el) => {
                                  if (el) {
                                    el.style.setProperty(
                                      "background",
                                      `${tagdata.custom_color}`,
                                      "important"
                                    );
                                  }
                                }}
                              >
                                <i class="fa fa-times whiteiconcolor" aria-hidden="true"></i>
                              </span>
                            ) : (
                              <span
                                onClick={(e) => openBox(e, tagdata.id, i)}
                                className={`action_icons last bg-${tagdata.class}`}
                                style={{ background: "#66B718" }}
                                ref={(el) => {
                                  if (el) {
                                    el.style.setProperty(
                                      "background",
                                      `${tagdata.custom_color}`,
                                      "important"
                                    );
                                  }
                                }}
                              >
                                <img title="Paint" src="assets/images/paint.png" />
                              </span>
                            )}
                          </div>
                        </div>
                        {colorpickerselect === tagdata.id && (
                          <div >
                            {open && (
                              <HexColorPicker
                                onChange={(e) =>
                                  changeTagcolor(e, tagdata.id, i)
                                }
                              />
                            )}
                          </div>
                        )}
                      </div>
                      // );
                    ))}
                  </>
                )}
              </div>
            </>
          )}
        </div>

        <ContactTag
          tagdata={groupcontact}
          tagcomponentarray={tagarray}
          callupdateback={updatepopupback}
        />
      </div>
    </div>
  );
}
