import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  GET_TEMPLATE,
  DELETE_TEMPLATE,
  SAVE_TEMPLATE,
  UPDATE_TEMPLATE,
} from "_helpers/api";
import Loader from "_components/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import TemplateMessage from "./templatemessage";
import Select from "react-dropdown-select";

export default function Template() {
  const { user: authUser } = useSelector((x) => x.auth);
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  let [status, updateStatus] = useState(true);
  const [editField, updateEditField] = useState(false);
  const [selectedinput, setSelectedinput] = useState(null);
  const [selectedcheck, setSelectedcheck] = useState(0);
  const [addclass, setAddClass] = useState(0);
  let [templatearray, updateTemplatearray] = useState([]);
  let [newcopytemplatearray, updateNewcopyTemplatearray] = useState([]);
  let [selectedtemplatearray, updateSelectedTemplatearray] = useState([]);
  let [fieldvalue, updateFieldvalue] = useState('');
  let [checkcontanct, setCheckcontanct] = useState(false);
  let [templatemessagenew, setTemplatemessagenew] = useState([]);

  useEffect(() => {
    fecthdata();
  }, []);

  const fecthdata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      // name: selectedinput,
    };
    axios
      .post(`${GET_TEMPLATE}`, data, {
        headers: headers,
      })
      .then((response) => {
        let finaldata = response.data.templateDetails;
        updateTemplatearray(finaldata);
        updateNewcopyTemplatearray(finaldata);
        updateStatus(false);
      })
      .catch((error) => {
        updateStatus(false);
      });
  };

  const handleChange = (e) => {
    console.log(e.target.checked)
    const { value, checked } = e.target;
    if (checked) {
      updateSelectedTemplatearray([...selectedtemplatearray, parseInt(value)])
    }
    else {
      updateSelectedTemplatearray(selectedtemplatearray.filter((e) => e !== parseInt(value)))

    }

  };
  const allSelect = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      var data = [];
      templatearray.map((key, index) => {

        data.push(key.id)
        console.log(key.id);
      })
      updateSelectedTemplatearray(data)

    }
    else {
      updateSelectedTemplatearray([])
    }
  }

  const getInput = () => {
    if (selectedtemplatearray.length == 0) {

    }
    else {
      Swal.fire({
        title: "Delete template folder",
        text: "Are you sure you want to delete Untimely updates and all the templates in it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#572F5B",
        cancelButtonColor: "#D9D9D9",
        confirmButtonText: "Yes, delete",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteApi();
        }
      });
    }

  };

  const deleteApi = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    console.log(headers)
    const data = {
      id: selectedtemplatearray,
    };
    axios
      .post(DELETE_TEMPLATE, data, {
        headers: headers,
      })
      .then((response) => {
        console.log(response)
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fecthdata();
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => { });
  };
  const showaddtag = () => {
    updateEditField(true);
  };
  const addfiledvalue = (e) => {
    console.log(e.target.value)
    //setSelectedinput("");
    updateFieldvalue(e.target.value);
  }

  const savedtemplate = (e) => {
    if (fieldvalue == '') {
      toast.error("Template Name Can not Blank", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authUser.token}`,
      };
      console.log(headers)
      const data = {
        name: fieldvalue,
      };
      axios
        .post(SAVE_TEMPLATE, data, {
          headers: headers,
        })
        .then((response) => {
          console.log(response)
          if (response.data.status == 200) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            fecthdata();
            updateEditField(false)
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => { });
    }
  }

  const sorttemplate = (data) => {
    console.log(data[0].value);
    var tempdata = templatearray;
    if (data[0].value == "A-Z") {
      tempdata.sort((a, b) => a.name.localeCompare(b.name));
    } else if (data[0].value == "Z-A") {
      tempdata.sort((a, b) => b.name.localeCompare(a.name));
    } else if (data[0].value == "oldest-first-asc") {
      tempdata.sort((a, b) => a.id - b.id);
    } else if (data[0].value == "newest-first-desc") {
      tempdata.sort((a, b) => b.id - a.id);
    } else {
      tempdata = templatearray;
      console.log(tempdata);
    }
    updateTemplatearray(tempdata);
    forceUpdate();
  };
  const options = [
    {
      label: "By A-Z",
      value: "A-Z",
    },
    {
      label: "By Z-A",
      value: "Z-A",
    },
    {
      label: "Oldest first",
      value: "oldest-first-asc",
    },
    {
      label: "Newest first",
      value: "newest-first-desc",
    },
  ];
  const searchinput = (data) => {
    var input_value = data.target.value;
    if (input_value == "") {
      updateTemplatearray(newcopytemplatearray);
      forceUpdate();
    } else {
      var tempdata = newcopytemplatearray;
      const filteredArray = tempdata.filter((item) =>
        item.name.toLowerCase().includes(input_value.toLowerCase())
      );
      if (filteredArray.length == 0) {
        updateTemplatearray([]);
      } else {
        updateTemplatearray(filteredArray);
      }
      forceUpdate();
    }
  };


  const handleValue = (e) => {
    setSelectedinput(e.target.value);
  };
  const edittemplate = (e, id, index) => {
    console.log(selectedinput);

    templatearray[index].name = selectedinput;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      name: selectedinput,
    };
    axios
      .post(UPDATE_TEMPLATE + "/" + id, data, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status == 200) {
          toast.success(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        updateStatus(false);
      });
    setSelectedcheck(0);
    setSelectedinput(null);
  };

  const closeTemplteField = () => {
    updateEditField(false)
  }
  const showtemplate = (e, id, name) => {
    e.preventDefault();
    setSelectedcheck(id);
    setSelectedinput(name);
  };

  const checktemplatemessage = (e, data) => {
    console.log(data)
    setAddClass(data.id)
    setCheckcontanct(true);
    setTemplatemessagenew(data);
    forceUpdate();
  };
  return (
    <div className="col-md-10 right_side">
      <div className="row head_name">
        <div className="col-md-5 left_col">
          <h3>My templates</h3>
        </div>
        <ToastContainer />
      </div>
      <div className="row tags_sec templates_sec">
        <div className="col-md-4 template_col_sec">
          <div className="template_cols">
            <div className="d-flex">
              <div className="head_top search_field ">
                <div className="input-group">
                  <input type="text" className="form-control" onChange={(e) => searchinput(e)} placeholder="Search" />
                  <span className="input-group-text">
                    <img src="assets/images/search_black.png" /></span>
                </div>
              </div>
              <div className="head_top actions action_last_col">
                <div className="dropdown">
                  <Select
                    className="dropdown_tag_new"
                    options={options}
                    onChange={(values) => sorttemplate(values)}
                  />
                </div>
              </div>
            </div>
            <div className="d-grid">
              <a href="#" className="add_folder btn-block" onClick={showaddtag}>
                <img src="assets/images/create_folder.png" />add new folder</a>
            </div>


            {templatearray.length > 0 ? <><div className="d-flex select_folder">
              <div className="select_folder_col">
                <input type="checkbox" onChange={(e) => allSelect(e)}
                  checked={selectedtemplatearray.length == templatearray.length}
                /><span >Select all folders</span></div>
              <div className="select_folder_col_1">
                <a href="#" onClick={getInput}>
                  <img src="assets/images/trash.png" data-bs-toggle="modal" data-bs-target="#deleteModal" /></a>
              </div>
            </div></> : null}


            <>
              {editField && (
                <div className="d-flex template_col">
                  <input type="text" className="form-control" onChange={(e) => addfiledvalue(e)} />
                  <a href="#" onClick={savedtemplate} style={{ margin: "8px" }}>
                    <img src="assets/images/check.png" />
                  </a>
                  <a href="#" onClick={closeTemplteField} style={{ margin: "8px" }}>
                  <i class="fa fa-times" aria-hidden="true"></i>
                  </a>
                </div>
              )}
            </>
            {status === true ? (
              <Loader />
            ) : (
              <>
                {templatearray.length == 0 ? (
                  <h4 style={{ textAlign: "center", marginTop: "15px" }}>No data found</h4>
                ) : (
                  <>
                    {templatearray.map((template, i) => (
                      <div className="d-flex template_col" key={template.id}>
                        <div style={{ lineHeight: "50px" }}>
                          <input type="checkbox"
                            className="item"
                            id={template.id}
                            name="contactId"
                            value={template.id}
                            checked={selectedtemplatearray.includes(template.id)}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                        <div className="template_tags">
                          {selectedcheck === template.id ? (
                            <div style={{ "display": "flex", justifyContent: "space-between" }}>
                              <input
                                defaultValue={template.name}
                                type="text"
                                className="form-control"
                                style={{ background: "none", border: "none", "flex": "0.9" }}
                                onChange={handleValue}
                                readOnly={selectedcheck === template.id ? false : true}
                              />
                              <img src="assets/images/check.png" onClick={(e) => edittemplate(e, template.id, i)}
                                style={{ "height": "17px", "marginTop": "10px" }} />
                            </div>
                          ) : (
                            <div style={{ "display": "flex", justifyContent: "space-between" }}>
                              <h3 className={addclass == template.id ? "template_button_active" : ""} style={{ "flex": "0.9" }} onClick={(e) => checktemplatemessage(e, template)}>{template.name} ({template.countmessage_count})</h3>
                              <img src="assets/images/pencil_new.png" onClick={(e) => showtemplate(e, template.id, template.name)}
                                style={{ "height": "17px", "marginTop": "10px" }} />
                            </div>

                          )}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {checkcontanct && (
          <TemplateMessage
            templatedata={templatemessagenew}
            tagcomponentarray={templatearray}
          />
        )}
      </div>
    </div>

  );
}
