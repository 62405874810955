import React from 'react'
import { useState } from 'react'
import axios from 'axios';
import { FORGOT_PASSWORD } from '_helpers/api';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";



const ForgotPassword = () => {

    const [password, setPassword] = useState();
    const [showSpinner, setShowSpinner] = useState(true);

    const changeHandler = (e) => {
        setPassword(e.target.value);

    }
    const submitPasswordMail = () => {
        setShowSpinner(false);
        const headers = {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${authUser.token}`,
        };
        const data = {
            email: password
        };
        axios
            .post(`${FORGOT_PASSWORD}`, data, {
                headers: headers,
            })
            .then((response) => {
                setShowSpinner(true);
                if (response.data.status === 200) {

                    toast.success(response.data.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
                console.log(response);
                let finaldata = response.data.data;
                console.log(finaldata);

                // updateStatus(false);
            })
            .catch((error) => {
                // updateStatus(false);
            });

    }

    return (
        <div className="row login_pages">
            <ToastContainer />
            <div className="col-md-12 text-center mt-5">
                <img src="assets/images/chatsilo_login_icon.png" alt='' />
            </div>
            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4 p-0">
                    <div className="login_box">
                        <h3>Forgot your Password?</h3>
                        <p>Don’t worry, we’ve got you covered</p>
                        <div className="input-group mt-3 mb-3">
                            <span className="input-group-text"><img src="assets/images/envelope.png" alt='' /></span>
                            <input type="text" onChange={changeHandler} value={password} className="form-control" placeholder="Enter your email" />
                        </div>
                        <div className="col-md-12 d-grid">
                            {showSpinner ? <button type="submit" onClick={submitPasswordMail} className="btn btn-block">Send my Password</button> : <button type="submit" disabled onClick={submitPasswordMail} className="btn btn-block"><span className="spinner-border spinner-border-sm mr-1"></span> Send my Password</button>}
                        </div>
                    </div>
                    <h4>Already have a license key? <NavLink as={Link} className="nav-link" activeClassName="is-active" to="/login">

                        <span className="menu_links">Login</span>
                    </NavLink></h4>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
