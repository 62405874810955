import { NavLink, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Nav,
  Navbar,
  NavDropdown,
  Form,
  Button,
} from "react-bootstrap";

import { authActions } from "_store";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

export { NavbarComponent };

function NavbarComponent() {
  const authUser = useSelector((x) => x.auth.user);
  const dispatch = useDispatch();
  const logout = () => dispatch(authActions.logout());

  // only show nav when logged in
  if (!authUser) return null;

  return (
    <Row>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="dark"
        className="header"
      >
        <Container fluid>
          <Navbar.Brand href="#home">
            <img src="assets/images/logo.png" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="m-auto">
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="form-control"
                  aria-label="Search"
                />
                <Button variant="outline-success" className="input-group-text">
                  <img src="assets/images/search.png" />
                </Button>
              </Form>
            </Nav>
            <Nav>
              <Nav.Link href="#deets" className="mt-2">
                <img src="assets/images/notification.png" />
              </Nav.Link>
              <div class="dropdown">
                <img src="assets/images/user.png" className="dropbtn" />
                <div class="dropdown-content">
                  <Link to="/profile">profile</Link>
                  <Link to="" onClick={logout}>logout</Link>
                 
                </div>
              </div>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Row>
  );
}
