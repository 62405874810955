import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  GET_TAG_USER,
  USER_TAG_NOTES,
  UPDATE_USER_TAG_NOTES,
  DELETE_TAG_NOTES,
} from "_helpers/api";
import Loader from "_components/loader";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import Swal from "sweetalert2";
import SavenotesComponent from "./savenotes";

export default function ContactTag(props) {
  const [, updateState] = useState({});
  const [show, setShow] = useState(false);
  const forceUpdate = useCallback(() => updateState({}), []);
  const [loader, setLoader] = useState(false);
  const [usercontact, setUsercontact] = useState([]);
  const [selectedinput, setSelectedinput] = useState("");
  const { user: authUser } = useSelector((x) => x.auth);
  const { primary: primaryid } = useSelector((x) => x.auth);
  const [notesshow, setNotesshow] = useState(false);
  const [usernotes, setUsernotes] = useState([]);
  const [currentnotsname, setCurrentnotsname] = useState("");
  const [setimageurl, setImageurl] = useState("");
  const [showupdate, setShowupdate] = useState(false);
  const [notesuser, setNotesuser] = useState([]);

  const handleShowUpdate = (e, tagdata) => {
    setNotesuser(tagdata);
    setShowupdate(true);
    forceUpdate();
  };
  useEffect(() => {
    setNotesshow(false);
    console.log(primaryid)
    fecthdata();
  }, [props.tagdata.id, primaryid]);

  const fecthdata = () => {
    console.log(props.tagdata.name);
    setLoader(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      tag_id: [props.tagdata.id],
      fb_account_id: primaryid,
    };
    axios
      .post(`${GET_TAG_USER}`, data, {
        headers: headers,
      })
      .then((response) => {
        setLoader(false);
        if (response.data.status == 200) {
          setUsercontact(response.data.tagUserDetails);
        } else {
          setUsercontact([]);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };
  const usernotesget = (e, tagdata) => {
    setNotesshow(true);
    setCurrentnotsname(tagdata.fb_name);
    forceUpdate();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      fb_user_id: tagdata.fb_user_id,
    };
    axios
      .post(`${USER_TAG_NOTES}`, data, {
        headers: headers,
      })
      .then((response) => {
        setLoader(false);
        if (response.data.status == 200) {
          setUsernotes(response.data.notes);
        } else {
          setUsercontact([]);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const usernotesupdate = (e, datavalue, i) => {
    usernotes[i].description = e.target.value;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      fb_user_id: datavalue.fb_user_id,
      description: e.target.value,
    };
    axios
      .post(`${UPDATE_USER_TAG_NOTES}/${datavalue.id}`, data, {
        headers: headers,
      })
      .then((response) => {
        setLoader(false);
        if (response.data.status == 200) {
        } else {
        }
      })
      .catch((error) => { });
  };

  const setimage = (e, i) => {
    usercontact[i].profile_pic = "assets/images/empty.png";
    forceUpdate();
  };

  const deletenotes = (e, tagdata, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setUsernotes((current) =>
          current.filter((array) => array.id !== tagdata.id)
        );
        deleteApi(tagdata.id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const deleteApi = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    // const data = {
    //   id: selectedinput,
    // };
    axios
      .get(DELETE_TAG_NOTES + "/" + id, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => { });
  };

  const checkGroupnotes = (e, data) => { };

  const updatemodalfalse = () => {
    setShowupdate(false);
    props.callupdateback();
  };
  return (
    <div className="col-md-3 contacts_sec" data-simplebar="init">
      <div className="simplebar-wrapper">
        <div className="simplebar-height-auto-observer-wrapper">
          <div className="simplebar-height-auto-observer"></div>
        </div>
        <div className="simplebar-mask">
          <div className="simplebar-offset">
            <div className="simplebar-content-wrapper">
              {!notesshow ? (
                <div className="simplebar-content">
                  {props.tagdata.name == undefined ? '' :
                    <h3>
                      {props.tagdata.name} ({props.tagdata.users})
                    </h3>}
                  {loader === true ? (
                    <Loader />
                  ) : (
                    <>
                      {usercontact.length == 0 && props.tagdata.name != undefined ? (
                        <h4 style={{ textAlign: "center", marginTop: "20px" }}>
                          No data found
                        </h4>
                      ) : (
                        <>
                          {usercontact.map((tagdata, i) => (
                            <div className="row contacts_list" key={i}>
                              <div className="col-9 contact_name">
                                <div style={{ display: "flex" }}>
                                  {tagdata.profile_pic == "" ? (
                                    <img
                                      src="assets/images/empty.png"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                        marginRight: "15px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={tagdata.profile_pic}
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                        marginRight: "15px",
                                      }}
                                      onError={(e) => setimage(e, i)}
                                    />
                                  )}
                                  <h4>{tagdata.fb_name}</h4>
                                </div>
                              </div>
                              <div className="col-3 text-end">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="dropdown_arrow_toggle"
                                  >
                                    <img src="assets/images/list.png" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={(e) => usernotesget(e, tagdata)}
                                    >
                                      Notes
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={(e) =>
                                        handleShowUpdate(e, tagdata)
                                      }
                                    >
                                      Move To
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div className="simplebar-content">
                  <h3>Notes for {currentnotsname}</h3>
                  {loader === true ? (
                    <Loader />
                  ) : (
                    <>
                      {usernotes.length == 0 ? (
                        <h4 style={{ textAlign: "center", marginTop: "20px" }}>
                          No data found
                        </h4>
                      ) : (
                        <>
                          {usernotes.map((tagdata, i) => (
                            <div className="row contacts_list" key={i}>
                              <textarea
                                className="textareaclass"
                                defaultValue={tagdata.description}
                                onBlur={(e) => usernotesupdate(e, tagdata, i)}
                              ></textarea>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "5px",
                                }}
                              >
                                <p style={{ flex: 1 }}>
                                  {moment(new Date(tagdata.createdDate)).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                                <img
                                  src="assets/images/delete_icon.png"
                                  onClick={(e) => deletenotes(e, tagdata, i)}
                                />
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="simplebar-placeholder"></div>
      </div>
      <div className="simplebar-track simplebar-horizontal">
        <div className="simplebar-scrollbar"></div>
      </div>
      <div className="simplebar-track simplebar-vertical">
        <div className="simplebar-scrollbar"></div>
      </div>
      <SavenotesComponent
        updatemodal={showupdate}
        functionhide={updatemodalfalse}
        notesdata={notesuser}
      />
    </div>
  );
}
