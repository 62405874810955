import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";

import { history, fetchWrapper } from '_helpers';
import { LOGIN_API, PRIMARY_ACCOUNT } from '_helpers/api';

// create slice

const name = 'auth';
const initialState      = createInitialState();
const reducers          = createReducers();
const extraActions      = createExtraActions();
const extraReducers     = createExtraReducers();
const primaryReducer    = createPrimaryReducer();

const slice = createSlice({ name, initialState, reducers, extraReducers, primaryReducer });

// exports

export const authActions = { ...slice.actions, ...extraActions};
export const primaryAction = { ...slice.actions, ...primaryReducer };
export const authReducer = slice.reducer;


// implementation  

function  createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null,
        primary:localStorage.getItem('primary')
    }
}

function createReducers() {
    return {
        logout,
        primary,
    };

    function logout(state) {
        state.user = null;
        localStorage.removeItem('user');
        history.navigate('/login');
    }

    function primary(state,action) {
        let primaryId = action.payload.actionId
        localStorage.setItem('primary',primaryId)
        state.primary = primaryId;
    }
}

function createExtraActions() {

    return {
        login: login()
    };

    function login() {

        return createAsyncThunk(
            `${name}/login`,
            async ({ email, password }) => await fetchWrapper.post(`${LOGIN_API}`, { email, password })
        );
    }
}

function primaryaccount(token) {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const data = {
        // name: selectedinput,
      };
      axios
        .post(`${PRIMARY_ACCOUNT}`, data, {
          headers: headers,
        })
        .then((response) => {
            localStorage.setItem('primary', response.data.primary.fb_account_id);
        //   let finaldata = response.data.tagDetails;
        //   updateTagarray(finaldata);
        //   updateNewcopytagarray(finaldata);
        //   updateStatus(false);
        })
        .catch((error) => {
        //  updateStatus(false);
        });
}

function createExtraReducers() {
    return {
        ...login()
    };

    function login() {

        var { pending, fulfilled, rejected } = extraActions.login;
        
        return {
            [pending]: (state) => {
                state.error = null;
                console.log(state.error)

            },
            [fulfilled]: (state, action) => {
                const user = action.payload;
                if (user['status'] == 200) {
                    localStorage.setItem('user', JSON.stringify(user['user']));
                    state.user = user['user'];
                    primaryaccount(user['user'].token)
                    // get return url from location state or default to home page
                    const { from } = history.location.state || { from: { pathname: '/' } };
                   history.navigate(from);

                }
                else {
                    state.error = user['message'];
                }

            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}

function createPrimaryReducer() {
    return {
        ...primary
    };

    function primary(state, action) {
        console.log('yes harminder is here');
    }
}