import React from 'react'
import { useState } from 'react'
import axios from 'axios';
// import { FORGOT_PASSWORD } from '_helpers/api';
// import { Link } from 'react-router-dom';
// import { NavLink } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import { RESET_PASSWORD } from '_helpers/api';
import { useParams } from 'react-router-dom';

const ResetPassword = () => {
    const { token } = useParams();
    console.log(token);
    var token2 = token.split(":");
    token2 = (token2[1]);

    const [password, setPassword] = useState({
        password: "",
        confirm_password: ""
    });

    const changeHandler = (e) => {
        const { value, name } = e.target;
        console.log(value, name);

        setPassword(() => {
            return {
                ...password,
                [name]: value
            }
        })

    }


    const submitPassword = () => {

        if (password.password === password.confirm_password) {
            const headers = {
                "Content-Type": "application/json",
                // Authorization: `Bearer ${authUser.token}`,
            };
            const data = {
                password: password.password,
                passwordToken: token2,
            };
            axios
                .post(`${RESET_PASSWORD}`, data, {
                    headers: headers,
                })
                .then((response) => {
                    if (response.data.status === 200) {

                        toast.success(response.data.msg, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        toast.error(response.data.msg, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                    console.log(response);
                    let finaldata = response.data.data;
                    console.log(finaldata);

                    //updateStatus(false);
                })
                .catch((error) => {
                    // updateStatus(false);
                });

        } else {
            toast.error("Confirm Password Must match", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    return (
        <div className="row login_pages">
            <ToastContainer />
            <div className="col-md-12 text-center mt-5">
                <img src="/assets/images/chatsilo_login_icon.png" alt='' />
            </div>
            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4 p-0">
                    <div className="login_box">
                        <h3>Reset your Password?</h3>
                        <p>Don’t worry, we’ve got you covered</p>
                        <div className="input-group mt-3 mb-3">
                            <span className="input-group-text"><img src="/assets/images/key.png" alt='' /></span>
                            <input type="password" name="password" onChange={changeHandler} value={password.password} className="form-control" placeholder="Enter New Password" />
                        </div>
                        <div className="input-group mt-3 mb-3">
                            <span className="input-group-text"><img src="/assets/images/key.png" alt='' /></span>
                            <input type="password" name="confirm_password" onChange={changeHandler} value={password.confirm_password} className="form-control" placeholder="Confirm New Password" />
                        </div>
                        <div className="col-md-12 d-grid">
                            <button type="submit" onClick={submitPassword} className="btn btn-block">Reset Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
