import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { GET_TAG_WITH_COUNTER, UPDATE_TAG_POPUP_NEW } from "_helpers/api";
import Loader from "_components/loader";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

export default function SaveTagComponent(props) {
  console.log(props);
  const [showupdate, setShowupdate] = useState(props.updatemodal);
  const { user: authUser } = useSelector((x) => x.auth);
  const [tagmodal, updateTagmodal] = useState([]);
  const [checked, setChecked] = useState([]);
  const [copytagmodal, updateCopytagmodal] = useState([]);
  let [status, updateStatus] = useState(true);

  useEffect(() => {
    fecthdata();
    //const { users } = useSelector((x) => x.users);
    console.log("i fire once", props.notesdata);
  }, [props.updatemodal]);

  const fecthdata = () => {
    updateStatus(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      // name: selectedinput,
    };
    axios
      .post(`${GET_TAG_WITH_COUNTER}`, data, {
        headers: headers,
      })
      .then((response) => {
        let finaldata = response.data.tagDetails;
        updateTagmodal(finaldata);
        updateCopytagmodal(finaldata);
        updateStatus(false);
      })
      .catch((error) => {
        updateStatus(false);
      });
  };

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    console.log(updatedList);
    setChecked(updatedList);
  };

  const updatetagpopup = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authUser.token}`,
    };
    const data = {
      tagIds: checked,
      fb_account_id: props.notesdata.account_fb_id,
      fbName: props.notesdata.fb_name,
      fbUserId: props.notesdata.fb_user_id,
      numericFbId: props.notesdata.numeric_fb_id,
      profilePic: props.notesdata.profile_pic,
      taggedId: props.notesdata.id,
    };
    axios
      .post(`${UPDATE_TAG_POPUP_NEW}`, data, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data);

        if (response.data.status == 200) {
          console.log(response.data);
          props.functionhide();
          // setUsercontact(response.data.tagUserDetails);
        } else {
        }
      })
      .catch((error) => {});
  };

  const searchinput = (data) => {
    var input_value = data.target.value;
    if (input_value == "") {
      updateTagmodal(copytagmodal);
    } else {
      var tempdata = copytagmodal;
      const filteredArray = tempdata.filter((item) =>
        item.name.toLowerCase().includes(input_value.toLowerCase())
      );
      if (filteredArray.length == 0) {
        updateTagmodal([]);
      } else {
        updateTagmodal(filteredArray);
      }
    }
  };
  return (
    <>
      <Modal show={props.updatemodal} onHide={props.functionhide}>
        <Modal.Header closeButton style={{ border: 0 }}>
          <Modal.Title>TAGS</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "400px", "overflow-y": "scroll" }}>
          {status === true ? (
            <Loader />
          ) : (
            <>
              <input
                type="text"
                name="tagdataid"
                className="form-control"
                onChange={searchinput}
                placeholder="Tag Search"
              />
              {tagmodal.length == 0 ? (
                <h5 style={{ textAlign: "center", marginTop: "20px" }}>
                  No Tag found
                </h5>
              ) : (
                <>
                  {tagmodal.map((tagdata, i) => (
                    // {data.map((tagdata, i) => {
                    //   return (
                    <div className="tag_col_sec" key={i}>
                      <div
                        className={`tag_cols bg-${tagdata.class} updatmodaltag`}
                        style={{
                          background: `${tagdata.custom_color}`,
                        }}
                      >
                        <input
                          type="checkbox"
                          name="tagdataid"
                          value={tagdata.id}
                          onChange={handleCheck}
                        />
                        <h2>
                          {tagdata.name} ({tagdata.users})
                        </h2>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer style={{ border: 0 }}>
          <a
            href="#"
            className="add_tag button_global"
            onClick={(e) => updatetagpopup(e)}
          >
            Update Tag
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
}
