import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { IMPORT_TAG } from "_helpers/api";
import Loader from "_components/loader";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

export default function ImportTag(props) {
  const [show, setShow] = useState(false);
  const [csvFile, setCsvFile] = useState();
  const { user: authUser } = useSelector((x) => x.auth);
  const [validated, setValidated] = useState(false);
  const [errormsg, setErrorMsg] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleValue = (e) => {
    setCsvFile(e.target.files[0]);
    console.log(e.target.files[0]);
  };
  const importsavetag = () => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${authUser.token}`,
    };

    const formData = new FormData();
    formData.append("file", csvFile);
    axios
      .post(IMPORT_TAG, formData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response);  
        if (response.data.status == 200) {
          toast.success(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          props.tagdata(response.data.tags);
          setErrorMsg();
          handleClose();
          setCsvFile();
        } else {
          setErrorMsg(response.data.msg);
          console.log(errormsg);
          //   toast.error(response.data.msg, {
          //     position: toast.POSITION.TOP_RIGHT,
          //   });
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <a href="#" onClick={handleShow}>
        <img src="assets/images/Logout.png" />
      </a>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ border: 0 }}>
          <Modal.Title>ADD File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form enctype="multipart/form-data">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Upload Csv File</Form.Label>
              <Form.Control
                type={"file"}
                accept={".csv"}
                className={
                  validated ? "redBordervalidationerror" : "originalClass"
                }
                placeholder="Enter Tag Name"
                autoFocus
                onChange={(e) => handleValue(e)}
              />
              {errormsg != "" ? (
                <h6 style={{ color: "red" }}>{errormsg}</h6>
              ) : (
                ""
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ border: 0 }}>
          <a
            href="#"
            className="add_tag button_global"
            onClick={(e) => importsavetag(e)}
          >
            <img src="assets/images/add.png" />
            Import
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
}
