import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Facebook } from "_components/dashboard/facebook";
import { Instagram } from "_components/dashboard/instagram";
import { Twitter } from "_components/dashboard/twitter";

//import { extraActions } from "_store";

export default function Home() {
  // const dispatch = useDispatch();
  // const { user: authUser } = useSelector((x) => x.auth);
  // //const { users } = useSelector((x) => x.users);

  // useEffect(() => {
  //   //dispatch(userActions.getAll());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div class="col-md-10 right_side">
      <div class="row head_name">
        <div class="col-md-3 left_col">
          <h3>Dashboard</h3>
        </div>
      </div>
      <Tabs
        defaultActiveKey="Facebook"
        id="uncontrolled-tab-example"
        className="nav nav-tabs"
      >
        <Tab eventKey="Facebook" title="Facebook" className="nav-item">
          <Facebook />
        </Tab>
        <Tab eventKey="Instagram" title="Instagram" className="nav-item">
          <Instagram />
        </Tab>
        <Tab eventKey="Twitter" title="Twitter" className="nav-item">
          <Twitter />
        </Tab>
      </Tabs>
    </div>
  );
}
