export const BASE_URL = process.env.REACT_APP_API_URL;
export const WEBAPP_BASE_URL = process.env.REACT_APP_EXTENSION_API_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const LOGIN_API = BASE_URL + '/loginnew';
export const COUNT_USER_DATA_API = WEBAPP_BASE_URL + '/countuserdata';
export const GET_TAG_WITH_COUNTER = WEBAPP_BASE_URL + '/getTagsWithCounterApi';
export const UPDATE_TAG = WEBAPP_BASE_URL + '/updatetag';
export const DELETE_TAG = WEBAPP_BASE_URL + '/deletetag';
export const SAVE_TAG = WEBAPP_BASE_URL + '/savetagnew';
export const UPDATE_TAG_COLOR = WEBAPP_BASE_URL + '/updatetagcolor';
export const EXPORT_TAG = WEBAPP_BASE_URL + '/exporttag';
export const IMPORT_TAG = WEBAPP_BASE_URL + '/importtag';
export const GET_TAG_USER = WEBAPP_BASE_URL + '/gettagusernew';
export const USER_TAG_NOTES = WEBAPP_BASE_URL + '/getnotesnew';
export const UPDATE_USER_TAG_NOTES = WEBAPP_BASE_URL + '/saveUserNoteNew';
export const DELETE_TAG_NOTES = WEBAPP_BASE_URL + '/deletusernotesnew';
export const UPDATE_TAG_POPUP_NEW = WEBAPP_BASE_URL + '/update_tag_from_popup_new';
export const GET_TEMPLATE = WEBAPP_BASE_URL + '/gettemplatenew';
export const DELETE_TEMPLATE = WEBAPP_BASE_URL + '/templateDeletenew';
export const SAVE_TEMPLATE = WEBAPP_BASE_URL + '/saveTemplatenew';
export const UPDATE_TEMPLATE = WEBAPP_BASE_URL + '/updatetemplatenew';
export const TEMPLATE_MESSAGE = WEBAPP_BASE_URL + '/getTemplateMessagenew';
export const UPDATE_TEMPLATE_MESSASGE = WEBAPP_BASE_URL + '/updatemessage';
export const DELETE_TEMPLATE_MESSAGE = WEBAPP_BASE_URL + '/deletetemplatemessagenew';
export const ADD_TEMPLATE_MESSAGE = WEBAPP_BASE_URL + '/saveMessagenew';
export const SENT_FRIEND_REQUEST = WEBAPP_BASE_URL + '/getSentFriendRequestWithCountnew';
export const SENT_BULK_MESSAGES = WEBAPP_BASE_URL + '/getSentBulkMessagesWithCountnew';


export const SENT_FRIEND_REQUEST_DELETE = WEBAPP_BASE_URL + '/deleteMultipleSentFriendRequestnew';
export const GET_USER_DATA = WEBAPP_BASE_URL + '/menew';
export const PRIMARY_ACCOUNT = WEBAPP_BASE_URL + '/primaryaccount';
export const UPDATE_USER_PROFILE = WEBAPP_BASE_URL + '/updateprofile';
export const CHATSILO_FACEBOOK_TAB = WEBAPP_BASE_URL + '/chatsilodatacount';
export const CHATSILO_FRIEND_REQUEST_DATA = WEBAPP_BASE_URL + '/chatsilofriendrequestdata';
export const CHATSILO_BULK_MESSAGE_SENT_DATA = WEBAPP_BASE_URL + '/chatsilobulkmessagedata';
export const TARGETED_FRIEND_REQUEST = WEBAPP_BASE_URL + '/targeted_friend_request';
export const FORGOT_PASSWORD = WEBAPP_BASE_URL + '/forgotpassword/create';
export const RESET_PASSWORD = WEBAPP_BASE_URL + '/forgotpassword/reset';










